import en from './locales/en.json'
import de from './locales/de.json'

export default defineI18nConfig(() => ({
    legacy: false,
    globaljection: true,
    locales: [
        { code: 'en', iso: 'en-US', name: 'English' },
        { code: 'de', iso: 'de-DE', name: 'Deutsch' },
        { code: 'de', iso: 'de-CH', name: 'Deutsch' },
        { code: 'de', iso: 'de-AT', name: 'Deutsch' },
    ],
    detectBrowserLanguage: {
        useCookie: true,
        cookieKey: 'i18n_redirected',
        alwaysRedirect: true,
        fallbackLocale: 'en',
        customDomainMapping: {
            'en-US': 'en',
            'en-GB': 'en',
            'de-DE': 'de',
            'de-CH': 'de',
            'de-AT': 'de',
        },
    },
    strategy: 'prefix_and_default',
    messages: {
        en: en,
        de: de,
    }
}))